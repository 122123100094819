.controlContainer {
  display: flex;
  width: 100%;
  height: 100%;

  .controlContainer-category {
    background-color: #252525;
    flex-flow: column;
    flex-basis: 124px;
    overflow: auto;

    button {
      cursor: pointer;
      width: 100%;
      background: #2196f3;
      text-transform: uppercase;
      color: #ffffff;
      border: 0;
      margin: 1px 0px 0;
      outline: 0;
      height: 40px;
      font-weight: 600;
      font-size: 12px;
    }

    .btn-link {
      background: #963112;
    }

    button:hover {
      background: #cacaca;
      color: #474747;
    }

    button:active {
      background: #a0a0a0;
    }
  }

  .controlContainer-category::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }

  .controlContainer-category::-webkit-scrollbar {
    width: 6px;
  }

  .controlContainer-category::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f0f0f0;
    border: 1px solid rgb(112, 112, 112);
  }

  .controlContainer-content {
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: flex-start;
    justify-content: center;
    width: 100%;

    .content-singleBtn {
      
      padding: 2px 4px;
      height: 50px;
      min-width: 230px;
      position: relative;

      button {
        color: rgba(255, 255, 255, 1);
        background-color: #afd203;
        height: 100%;
        width: 100%;
        opacity: 1;
        border: transparent;
        border-radius: 5px;
        overflow: hidden;
        cursor: pointer;
        font-weight: bold;
        font-size: 28px;
      }

      button:hover {
        background-color: #d4ff00;
      }

      button:active {
        background-color: rgb(90, 90, 90);
      }
    }
    .content-singleBtnDelete {
      
      padding: 2px 4px;
      height: 60px;
      min-width: 230px;
      position: relative;

      button {
        color: #c62727;
        background-color: transparent;
        border-color: #c62727;
        border-width: 1;
        border-style: dotted;
        height: 100%;
        width: 100%;
        opacity: 1;
        border-radius: 20px;
        overflow: hidden;
        cursor: pointer;
        font-weight: bold;
        font-size: 28px;
      }

      button:hover {
        background-color: #c62727;
        color: white;
      }

      button:active {
        background-color: rgb(90, 90, 90);
      }
    }

    .content-doubleBtn {
      padding: 2px 4px;
      height: 50px;
      min-width: 230px;
      position: relative;

      button {
        color: rgba(255, 255, 255, 0.26);
        height: 100%;
        width: 50%;
        opacity: 1;
        border: 0;
        outline: none;
        overflow: hidden;
      }

      .left {
        cursor: pointer;
        background-color: #2998d3;
        border-radius: 8px 0 0 8px;
      }

      .left:hover {
        background: #00a6ff;
        color: #fff;
      }

      .left:active {
        background: #0098e9;
        color: #fff;
      }

      .right {
        cursor: pointer;
        background-color: #d34228;
        border-radius: 0 8px 8px 0;
      }

      .right:hover {
        background: #ff4444;
        color: #fff;
      }

      .right:active {
        background: #b10000;
        color: #fff;
      }

      .disabled {
        background-color: rgb(63, 63, 63) !important;
        cursor: default !important;
        color: rgba(255, 255, 255, 0.26) !important;
      }

      p {
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 4px;
        font-weight: bold;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        text-align: center;
        font-size: 28px;
        pointer-events: none;
      }
    }
  }
}
