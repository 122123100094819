
.sidebar {
  padding: 10px;
  overflow: auto;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;

  .aiTag {
    background-color: brown;
    padding: 8px 6px;
    margin: 10px 4px 10px 0;
    height: 34px;
    color: #d8a8a8;
  }
  

  .activeHighlight {
    border-bottom: 2px solid #ead36e !important;
  }

  .sidebar-item {
    display: flex;
    background-color: #f7f7f7;
    color: black;
    border-radius: 0 6px 6px 0;
    margin: 10px 0;
    font-size: 12px;
    cursor: pointer;
    height: 34px;
    transition: height 0.15s ease-out;
    overflow: hidden;
    position: relative;

    .item-btnBar {
      display: flex;
      overflow: hidden;
      position: absolute;
      margin-left: 10px;

      .btnBar-btn {
        background-color: rgb(220, 220, 220);
        color: #777777;
        padding: 6px 8px;
        margin: 0 1px;
        margin-top: 38px;
        margin-left: 6px;
        border-radius: 4px 4px 0 0;
        border: 1px solid #cacaca;
        font-weight: bold;
      }

      .btnBar-disabled {
        background: #979797;
        pointer-events: none;
        border: 0;
      }

      .btn-delete {
        color: #ececec;
        background-color: #ea6e6e;
        border: 1px solid #ca5c5c;
      }

      .btn-Highlight {
        font-size: 18px;
        padding: 3px;
        color: #8e8e8e;
      }

      .btnBar-btn:hover {
        background-color: rgb(206, 206, 206);
        color: #585858;
      }

      .btn-delete:hover {
        color: #dbdbdb;
        background-color: #e65757;
        border: 1px solid #bb4949;
      }

      .btnBar-btn:active {
        background-color: rgb(112, 112, 112);
        color: #d1d1d1;
      }

      /*.btnBar-btnGetActive {
        margin-top: 22px;
        background-color: transparent;
        color: #c5c514;
        border: none;
      }*/

      .btn-delete:active {
        color: #ececec;
        background-color: #cc4141;
        border: 1px solid #af3c3c;
      }
    }

    .item-colorBox {
      opacity: 0.5;
      width: 10px;
    }

    .item-name {
      width: 250px;
      padding: 10px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .item-time {
      padding: 10px;
      width: 90px;
    }

    .item-time:hover {
      color: #d27a7a;
    }
  }

  .sidebar-item-open {
    height: 64px;
    border-bottom: 1px solid #a7a7a7;

    .btnBar-btnGetActive {
      background-color: rgb(234, 211, 110) !important;
      border: 1px solid rgb(218, 193, 82) !important;
      color: #ececec !important;
    }

    .btnBar-btnGetActive:hover {
      background-color: rgb(216, 191, 79) !important;
    }
  }

  .right {
    border-radius: 6px 0 0 6px;
  }

  .center {
    border-radius: 0;
    background-color: #2f2f2f;
    color: #fff;
  }

  .sidebar-bottomBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #a7a7a7;
    bottom: 0px;
    left: 0px;
    background-color: #f7f7f7;
    border-top: 1px solid #ccc;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    margin: -10px;
    margin-top: 10px;
    overflow: hidden;
    height: 80px;

    span {
      padding: 10px;
    }
  }

  .sidebar-bottomBtn:hover {
    background-color: #cacaca;
    border-top: 1px solid rgb(145, 145, 145);
    color: #696969;
  }

  .sidebar-bottomBtn:active {
    background-color: #a8a8a8;
    color: #161616;
  }

  .sidebar-doubleInfoText {
    color: #123;
    text-align: center;
    padding: 6px 2px;
    margin: 0px -10px -10px -10px;
    background: #9f9f9f;
    font-size: 12px;
  }

  .active {
    background-color: #b2d3ba !important;
    color: #123 !important;
    cursor: unset !important;
    border-top: 0px !important;
  }
  
  .sidebar-doubleBtn {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 0 -10px;
    align-items: center;

    .doubleBtn-btn {
      color: #a7a7a7;
      bottom: 0px;
      left: 0px;
      background-color: #f7f7f7;
      border-top: 1px solid #ccc;
      padding: 10px;
      text-align: center;
      cursor: pointer;
      margin: -10px 0px -10px 0px;
      margin-top: 10px;
      overflow: hidden;
      width: 100%;
    }

    .doubleBtn-btn:hover {
      background-color: #cacaca;
      border-top: 1px solid rgb(145, 145, 145);
      color: #696969;
    }
  
    .doubleBtn-btn:active {
      background-color: #a8a8a8;
      color: #161616;
    }

    .doubleBtn-btn:first-child {
      border-right: 1px solid #ccc;
    }
  }

}

.loadingSwap {
  .loader {
    height: 32px !important;
    width: 32px !important;
    top: -6px !important;
    left: 116px !important;
  }
}

// deleteTag animation wait for req

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    background-color: transparent;
  }
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    background-color: rgba(255, 17, 0, 0.377);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    background-color: transparent;
  }
  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    background-color: rgba(255, 17, 0, 0.377);
  }
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  max-height: 34px;
}

//  req ok? swipe left div

@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(-300px);
    transform: translateX(-300px);
    max-height: 34px;
    margin-top: 0px;
  }
  100% {
    -webkit-transform: translateX(-300px);
    transform: translateX(-300px);
    max-height: 0px;
    margin-top: -10px;
  }
}
@keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(-300px);
    transform: translateX(-300px);
    max-height: 34px;
    margin-top: 0px;
  }
  100% {
    -webkit-transform: translateX(-300px);
    transform: translateX(-300px);
    max-height: 0px;
    margin-top: -10px;
  }
}

.slide-left {
  background-color: rgba(255, 17, 0, 0.377) !important;
  -webkit-animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.aiTag-denied {
  background: repeating-linear-gradient(45deg, #edbdbd, #edbdbd 10px, #d1d1d1 10px, #d1d1d1 20px);
  opacity: 0.7;
}

.aiTag-confirmed {
  background: repeating-linear-gradient(135deg, #bdedbd, #bdedbd 10px, #d1d1d1 10px, #d1d1d1 20px);
  opacity: 0.7;
}