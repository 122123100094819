.staige-player {
  position: relative;
  height: 100%;
  width: 100%;

  .content-player {
    position: absolute;
    height: 100%;
    width: 100%;
  }
}