.editoroverlay {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  pointer-events: none;

  .goalTimerOverlay {
    pointer-events: auto;
    
    .goalsCounter {
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      max-width: 600px;
      background-color: #f7f7f7;
      color: black;
      text-align: center;
      border-radius: 2px;

      display: flex;
      align-items: center;
      justify-content: space-around;
      white-space: nowrap;

      border-left: 10px solid #85a6bb;
      border-right: 10px solid #ff7d7e;
      box-shadow: 0px 0px 4px #000000;
      margin-top: 10px;

      .nonAbsolute {
        position: relative;
      }
      
      cursor: default;

      .club {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin: 0 10px;
        width: 100%;
      }

      .counter {
        padding: 6px 12px;
        background-color: #5a5a5a;
        color: #fff;
        border-radius: 8px;
        border-left: 1px solid #3c3c3c;
        border-right: 1px solid #3c3c3c;
      }

      .roundCounter {
        position: absolute;
        left: 0;
        margin-left: -26px;
        margin-top: -2px;
        background-color: #f7f7f7;
        padding: 1px 8px;
        border-radius: 0px 0px 4px 4px;
      }


      .t {
        width: 12px;
        height: 12px;
        margin: 3px;
        border: 1px solid #000;
        border-radius: 20px;
      }

      .tActive {
        width: 12px;
        height: 12px;
        margin: 3px;
        background: #2998d3;
        border-radius: 20px;
      }
    }

    .matchTimer {
      width: 500px;
      border-left: none;
      border-right: none;
      margin-top: 4px;
      border-radius: 0px 0px 4px 4px;
      color: #676767;
      padding-top: 2px;
    }
  }

  .timeMenu {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin-bottom: 8px;
    z-index: 1;

    .timeMenu-container {
      display: flex;
      background-color: #f7f7f7;
      color: black;
      padding: 4px 0px;
      box-shadow: 0px 0px 4px #0000009c;
      border-radius: 4px;
      pointer-events: auto;

      .container-box {
        margin: 0px 4px;
        display: flex;
        height: 100%;
        background-color: #dcdcdc;
        border: 1px solid #c5c5c5;

        .text {
          padding: 6px 14px 2px;
          cursor: default;
        }

        button {
          padding: 0px 10px;
          background-color: rgb(228, 208, 95);
          outline: none;
          border: none;
          cursor: pointer;
        }

        button:disabled {
          cursor: default;
          background-color: #5cc577;
          color: black;
        }
      }
    }
  }
}
