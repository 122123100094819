.app-header {
  .header-btnContainer-left {
    color: #47494a;
    position: absolute;
    left: 18px;
    top: -1px;
    cursor: pointer;

    span {
      color: #47494a;
      margin-right: 20px;
    }
  }

  .header-btnContainer-left:hover {
    color: #1b1b1b;
  }

  .header-btnContainer-left:active {
    color: #a0a0a0;
  }

  .header-btnContainer {
    display: flex;
    position: absolute;
    right: 10px;
    top: -1px;

    .btnContainer-btn {
      font-size: 12px;
      text-transform: uppercase;
    }

    .loader {
      width: 22px;
      height: 22px;
      margin-top: 18px;
    }
  }
}

.mbsc-page a:hover {
  text-decoration: none !important;
}
