.match {
  color: #fff;
  background: #fff;

  .match-container {
    display: flex;
    height: 100%;

    .container-sidebar {
      flex: 0 0 300px;
      background-color: #dcdcdc;
      z-index: 10;
      overflow: auto;
    }

    .container-sideba::-webkit-scrollbar-track {
      background-color: #f5f5f5;
    }

    .container-sidebar::-webkit-scrollbar {
      width: 10px;
    }

    .container-sidebar::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f0f0f0;
      border: 1px solid rgb(112, 112, 112);
    }

    .container-content {
      width: 100%;

      .controls {
        height: 38px !important;
        margin-bottom: 6px;

        .progress {
          bottom: 6px !important;
        }

        .position-indicator {
          background-color: #8a0407;
        }

        @media (max-width: 768px) {
          font-size: 12px !important;

          svg {
            font-size: 16px;
          }

          span {
            font-size: 16px;
          }
        }

        .progress-container {
          .position-indicator {
            background-color: #f00 !important;
          }

          .ZoomWrapper {
            height: 4px !important;

            .progress {
              --progressbarColor: #f00 !important;
            }
          }
        }
      }

      .video-player {
        .children {
          height: 100% !important;
          width: 100% !important;
        }
      }
    }
  }
}

.s-player-block__container {
  padding: 0 !important;
}