.timeMenu {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin-bottom: 60px;

  .timeMenu-container {
    display: flex;
    background-color: #f7f7f7;
    color: black;
    padding: 4px 0px;
    box-shadow: 0px 0px 4px #0000009c;
    border-radius: 4px;

    .container-wrapper-box {
      display: flex;
      flex-direction: column;
      align-items: center;

      .container-minutes {
        padding: 4px 0 0 0;
        font-size: 10px;

        input {
          width: 60px;
          text-align: center;
          padding-left: 14px;
          border-radius: 14px;
          border: 1px solid #b3b3b3;
        }
      }
    }

    .container-box {
      margin: 0px 4px;
      display: flex;
      height: 100%;
      background-color: #dcdcdc;
      border: 1px solid #c5c5c5;

      .text {
        padding: 6px 14px 2px;
        cursor: default;
      }

      button {
        padding: 0px 10px;
        background-color: rgb(228, 208, 95);
        outline: none;
        border: none;
        cursor: pointer;
      }

      button:disabled {
        cursor: default;
        background-color: #5cc577;
        color: black;
      }
    }
  }
}

.timeMenu-solo {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #090909;

  .timeMenu-container {
    display: flex;
    color: black;
    padding: 10px 0px;
    border-radius: 4px;

    .container-box {
      margin: 0px 4px;
      display: flex;
      height: 100%;
      background-color: #dcdcdc;
      border: 1px solid #717171;

      .text {
        padding: 6px 14px 2px;
        cursor: default;
      }

      button {
        padding: 0px 10px;
        background-color: rgb(228, 208, 95);
        outline: none;
        border: none;
        cursor: pointer;
      }

      button:disabled {
        cursor: default;
        background-color: #5cc577;
        color: black;
      }
    }
  }
}


.btn-timeInput {
  background: #f7f7f7;
  padding: 2px 6px 0px;
  border: 1px solid #d1d1d1;
  color: #123;
  display: flex;
  align-items: center;
  height: 22px;
  margin-top: 12px;
  cursor: pointer;
  pointer-events: auto;
}